:root {
  --yellow: #fff800;
  --dark: #000928;
  --green: #47a163;
  --pink: #fce1e6;
  --orange: #ff8000;
  --blue: #83f4ff;
}

.App {
  text-align: center;
  background-color: var(--dark);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: var(--dark);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: var(--pink);
  max-width: 500px;
  margin: 0 auto;
}

.App-link {
  color: var(--pink);
}

.App-Link-Card {
  background-color: var(--pink);
}
